<template>
  <v-container>
    <v-icon
      v-if="itemId"
      :icon="heartIcon(itemId)"
      :aria-label="addrem(itemId) + ' ' + itemTitle + ' ' + tofrom(itemId) + ' Wishlist'"
      size="large"
      color="primary"
      class="z-[3]"
      @click="heart(itemId)"
    />
  </v-container>
  <div 
    v-if="wishSnackbar" 
    class="snackbar elevation-10"
  >
    <p>This item has been added to your wishlist.</p>
  </div>
</template>

<script setup>

import { useUserDataStore } from '~~/stores/userData';
import { filter, find } from 'lodash-es'
import { useCurrentUser, useFirestore } from 'vuefire';
import { doc, setDoc, deleteDoc, getDoc, addDoc } from 'firebase/firestore'

const props = defineProps({
  id:{
    type:String,
    required:true
  },
  itemImageSrc:{
    type:String,
    required:true
  },
  itemTitle:{
    type:String,
    required:true
  },
  itemPrice:{
    type:Number,
    required:true
  },
  itemShipCost:{
    type:Number,
    required:true
  },
});

const itemId = ref(computed(()=>{return props.id}))
const itemImageSrc = computed(()=>{return props.itemImageSrc})
const itemTitle = computed(()=>{return props.itemTitle})
const itemPrice = computed(()=>{return props.itemPrice})
const itemShipCost = computed(()=>{return props.itemShipCost})
const wishSnackbar = ref(false)

const heartIcon = ref((id) => {
  if(isWished(id)){
    return 'mdi-heart'
  }else{
    return 'mdi-heart-outline'
  }
})

const addrem = ref((id)=> {
  if(isWished(id)){
    return 'Remove '
  } else {
    return 'Add '
  }
})

const tofrom = ref((id)=>{
  if(isWished(id)){
    return 'from'
  } else {
    return 'to'
  }
})

const isWished = (id) =>{
  if(useUserDataStore().userData!=undefined && useUserDataStore().userData.wishes!=undefined){
    let match = find(useUserDataStore().userData.wishes,(w)=>{return w.id==id})
    if(!match){
      return false
    }else{
      return true
    }
  }
  else{
    return false
  }
}


const heart = async (id) => {
  const match = find(useUserDataStore().userData.wishes,(w)=>{return id===w.id})
  if(!match){
    useUserDataStore().userData.wishes.push({
      id:id,
      photoURL:itemImageSrc,
      title:itemTitle,
    })
    //push to wisher subcollection
    if(useCurrentUser().value){
      //no matching allow stmnts???
      const wishersDocRef = doc(useFirestore(),'products',itemId.value,'wishers',useCurrentUser().value.uid)
      setDoc(wishersDocRef,{
        date:new Date(),
        wishPrice:itemPrice.value,
        wishShipCost:itemShipCost.value
      })
    }
    // snackbar.value = true
		wishSnackbar.value = true
		await delay(5000)
		wishSnackbar.value = false
  } else{
    //match found, let's remove it
    useUserDataStore().userData.wishes = filter(useUserDataStore().userData.wishes,(w)=>{return id!=w.id})
    //delete within subcollection
    if(useCurrentUser().value){
      const wishersDocRef = doc(useFirestore(),'products',itemId.value,'wishers',useCurrentUser().value.uid)
      deleteDoc(wishersDocRef)
    }
  }
}

const delay = ms => new Promise(res => setTimeout(res, ms));
</script>
